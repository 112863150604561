<script lang="ts" setup>
import { $generateOAuthUrl } from '@/helpers'
import { useToastStore, useUserStore } from '@/stores'

const { $toast } = useToastStore()
const userStore = useUserStore()
const appVersion = __APP_VERSION__

function gotoOAuth() {
  if (userStore.$state.has_openid) {
    $toast('已绑定微信')
    return
  }
  window.location.href = $generateOAuthUrl({ appId: 'wxc158d6225b1f290a', scope: 'snsapi_userinfo', redirect: `${window.location.origin}/authorize` })
}
</script>

<template>
  <div class="min-h-screen bg-white">
    <BasicTitle title="陪诊端" />

    <div class="p8 bg-#4095E5 text-white">
      <div class="flex items-center justify-between gap-4">
        <div class="flex items-center gap-4 flex-1 w-0">
          <img v-if="userStore.$state.avatar" :src="userStore.$state.avatar" class="w-15 h-15 rounded-full">
          <img v-else src="@/assets/default-avatar.png" class="w-15 h-15 rounded-full">
          <div>
            <p class="truncate">
              {{ userStore.$state.name }}
            </p>
          </div>
        </div>

        <div class="pr-6">
          <router-link :to="{ name: 'qrcode' }">
            <div>
              <i-bi-qr-code class="w-10 h-10 mx-auto" />
              <div class="text-sm mt-1">
                本人推荐码
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="flex items-center justify-center gap-4 border-t  border-current pt-6 mt-6 text-center">
        <router-link v-show="userStore.$state.is_level === 1" :to="{ name: 'distribution' }" class="w-24">
          <div class="text-xl font-medium truncate">
            {{ userStore.$state.have_escort_total }}
          </div>
          <div class="text-sm">
            二级分销
          </div>
        </router-link>

        <router-link :to="{ name: 'recommended' }" class="w-24">
          <div class="text-xl font-medium truncate">
            {{ userStore.$state.my_user_total }}
          </div>
          <div class="text-sm">
            本人推荐用户
          </div>
        </router-link>
      </div>
    </div>

    <div class="mt-4 px-4">
      <router-link :to="{ name: 'my-order' }" class="py-2 flex items-center justify-between border-b">
        <div class="flex items-center gap-4">
          <i-icon-park-outline-view-list />
          <p>直推订单</p>
        </div>
        <i-ic-outline-keyboard-arrow-right />
      </router-link>
      <router-link v-show="userStore.$state.is_level === 1" :to="{ name: 'distribution-order' }" class="py-2 flex items-center justify-between border-b">
        <div class="flex items-center gap-4">
          <i-icon-park-outline-log />
          <p>分销订单</p>
        </div>
        <i-ic-outline-keyboard-arrow-right />
      </router-link>
      <div
        class="py-2 flex items-center justify-between border-b"
        @click="gotoOAuth"
      >
        <div class="flex items-center gap-4">
          <i-ic-outline-wechat />
          <p>绑定微信</p>
        </div>
        <i-ic-outline-keyboard-arrow-right />
      </div>
      <router-link :to="{ name: 'change-mobile' }" class="py-2 flex items-center justify-between border-b">
        <div class="flex items-center gap-4">
          <i-bx-mobile-alt />
          <p>修改手机</p>
        </div>
        <i-ic-outline-keyboard-arrow-right />
      </router-link>
    </div>

    <p class="mt-20 text-xs text-#999 text-center">
      版本号: v{{ appVersion }}
    </p>
    <BasicTabbar />
  </div>
</template>
