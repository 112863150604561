<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { isMobile } from '@/utils'
import { $formatErrorMessage } from '@/helpers'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'

const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()

const form = ref({
  phone: '',
  code: '',
})

const countdown = ref(0)
async function onSendCode() {
  if (!isMobile(form.value.phone)) {
    $toast('请输入正确的手机号码')
    return
  }

  try {
    $showLoading()
    await api.post('/escort/user/send_phone_code', {
      phone: form.value.phone,
    })
    $toast('短信发送成功')
    countdown.value = 60
    startCountdown()
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}
function startCountdown() {
  if (countdown.value === 0)
    return
  setTimeout(() => {
    countdown.value = countdown.value - 1
    startCountdown()
  }, 1000)
}

const router = useRouter()
async function onSubmit() {
  try {
    $showLoading()
    await api.post('/escort/user/update_phone', {
      phone: form.value.phone,
      code: form.value.code,
    })
    $toast('手机号码修改成功')
    router.back()
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}
</script>

<template>
  <div class="min-h-screen px-8">
    <BasicTitle title="修改手机" />

    <div class="mt-20">
      <div class="mt-4 rounded-full overflow-hidden shadow">
        <input v-model="form.phone" type="number" maxlength="11" class="w-full h-10 px-4" placeholder="手机号码">
      </div>
      <div class="mt-4 rounded-full overflow-hidden shadow relative">
        <input v-model="form.code" class="w-full h-10 px-4" placeholder="验证码">
        <p v-if="countdown === 0" class="text-sm text-#467FF2 lh-10 px-4 absolute right-0 top-0" @click="onSendCode">
          发送验证码
        </p>
        <p v-else class="text-sm text-#999 lh-10 px-4 absolute right-0 top-0">
          {{ countdown }}秒后重新发送
        </p>
      </div>

      <button
        class="mt-4 w-full h-10 lh-10 text-white bg-#467FF2 rounded-full"
        @click="onSubmit"
      >
        确认修改
      </button>
    </div>
  </div>
</template>
