<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'

import { useRoute, useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { TICKET_STATUS } from '@/constants'

const infiniteRef = ref<HTMLElement>()

const route = useRoute()
const router = useRouter()

const status = ref(route.query.status ?? '3')

const list = ref<any>([])
// onMounted(async () => {
//   const status = tab.value === 'all' ? '' : tab.value === 'serving' ? '3' : '4'
//   const { data } = await api.get(`/escort/serv_order/list?status=${status}`)
//   list.value = data.items
// })

const page = ref(1)
const ending = ref(false)
const fetching = ref(false)
const error = ref(null)

function onChangeTab(value: string) {
  if (value === status.value)
    return
  status.value = value
  router.replace({ query: { status: value } })
}

async function fetchTickets() {
  fetching.value = true
  try {
    const { data } = await api.get(`/escort/serv_order/list?status=${status.value}&page=${page.value}`)
    list.value = list.value.concat(data.items)
    page.value = page.value + 1
    if (data.pageInfo.currentPage === data.pageInfo.totalPage)
      ending.value = true
  }
  catch (err: any) {
    error.value = err
  }
  finally {
    fetching.value = false
  }
}

useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchTickets()
  },
  { distance: 50 },
)

watch(() => route.fullPath, () => {
  list.value = []
  page.value = 1
  ending.value = false
  fetching.value = false
  error.value = null
  fetchTickets()
})
</script>

<template>
  <div ref="infiniteRef" class="h-screen pb-20 overflow-y-scroll bg-white">
    <BasicTitle title="服务单" />

    <div class="w-full h-10 flex items-center fixed left-0 bg-white">
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '3' }"
        @click="onChangeTab('3')"
      >
        服务中
      </p>
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '4' }"
        @click="onChangeTab('4')"
      >
        已完成
      </p>
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '' }"
        @click="onChangeTab('')"
      >
        全部
      </p>
    </div>

    <div class="mt-14 px-8">
      <router-link
        v-for="item in list" :key="item.id"
        :to="{ name: 'ticket', params: { id: item.id } }"
        class="block py-2 border rounded mb-4"
      >
        <div class="px-4 flex justify-between">
          <div>
            <p>{{ item.hospital }}</p>
            <p class="text-sm text-#999">
              全程陪诊
            </p>
          </div>
          <p v-if="TICKET_STATUS.SERVING === item.status" class="text-green">
            服务中
          </p>
          <p v-else-if="TICKET_STATUS.FINISHED === item.status" class="text-gray">
            已完成
          </p>
        </div>
        <div class="px-4 mt-2 pt-2 border-t">
          <p>就诊时间: {{ item.date }}</p>
          <p>医生: {{ item.doctor }}</p>
          <p>科室: {{ item.department }}</p>
          <p>就诊人: {{ item.patient.name }}</p>
        </div>
      </router-link>
    </div>

    <div v-if="ending" class="py-15px text-13px text-#999 text-center">
      我们是有底线的~
    </div>
    <div v-else-if="fetching" class="py-15px flex items-center justify-center gap-4 text-13px text-#999">
      <div class="animate-spin">
        <i-icon-park-outline-loading />
      </div>
      <p>加载中...</p>
    </div>

    <BasicTabbar />
  </div>
</template>
