import Vue from 'vue'
import { PiniaVuePlugin, createPinia } from 'pinia'
import VueRouter from 'vue-router'
import 'uno.css'
import '@/libs/flexible'
import PortalVue from 'portal-vue'
import { Button, DatetimePicker, NumberKeyboard, Uploader } from 'vant'
import 'vant/lib/button/style'
import 'vant/lib/datetime-picker/style'
import 'vant/lib/number-keyboard/style'
import 'vant/lib/uploader/style'

import router from '@/router'
import App from '@/App.vue'
import { disableAdjustFontSize } from '@/wechat'

Vue.config.productionTip = false

// Pinia
const pinia = createPinia()
Vue.use(PiniaVuePlugin)

// Router
Vue.use(VueRouter)

// Portal
Vue.use(PortalVue)

// Vant
Vue.use(DatetimePicker)
Vue.use(NumberKeyboard)
Vue.use(Uploader)
Vue.use(Button)

try {
  disableAdjustFontSize()
}
catch {}

// if (import.meta.env.MODE !== 'production') {
// new VConsole()
// }

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')
