/**
 * 一些业务相关的 helper 函数, 所有函数都以 `$` 开头
 */

import { v4 as uuid } from 'uuid'
import { format } from 'date-fns'
import { STORAGE_TOKEN, STORAGE_UUID } from '@/constants'

export function $uuid() {
  let id = window.localStorage.getItem(STORAGE_UUID)
  if (id)
    return id

  id = uuid()
  window.localStorage.setItem(STORAGE_UUID, id)
  return id
}

export function $getToken() {
  return window.localStorage.getItem(STORAGE_TOKEN)
}
export function $setToken(token: string) {
  window.localStorage.setItem(STORAGE_TOKEN, token)
}
export function $removeToken() {
  window.localStorage.removeItem(STORAGE_TOKEN)
}
export function $isLogin() {
  return Boolean($getToken())
}

export function $formatErrorMessage(error: any) {
  return error.response?.data.message || error.message
}

export function $getCurrentDate() {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss')
}

export function $formatDate(date: Date | string | number) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
}

export function $generateOAuthUrl({ appId, redirect, scope = 'snsapi_base', state = 'STATE' }: { appId: string; scope: 'snsapi_base' | 'snsapi_userinfo'; redirect: string; state?: string }) {
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${window.encodeURIComponent(redirect)}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
}
