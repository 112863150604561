<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import axios from 'axios'
import { $formatErrorMessage, $getToken } from '@/helpers'
import { useLoadingStore, useToastStore } from '@/stores'

const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()

// is weixin
function isWeixin() {
  return navigator.userAgent.toLowerCase().includes('micromessenger')
}

// blob to img base64
function blobToBase64(blob: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const res = reader.result as string
      resolve(res?.replace?.(/^data:text\/plain/, 'data:image/png'))
    }
    reader.readAsDataURL(blob)
  })
}

const imageUrl = ref('')
onMounted(async () => {
  try {
    $showLoading()
    const response = await axios.get('/escort/user/get_qr_code', {
      baseURL: import.meta.env.VITE_API_DOMAIN + import.meta.env.VITE_API_BASE,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${$getToken()}`,
      },
    })
    const imageBlob = new Blob([response.data], { type: response.headers['content-type'] })
    // imageUrl.value = URL.createObjectURL(imageBlob)
    imageUrl.value = await blobToBase64(imageBlob) as string
  }
  catch (error: any) {
    console.error(error)
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
})

function saveImage() {
  const a = document.createElement('a')
  a.href = imageUrl.value
  a.download = '推荐码.jpg'
  a.click()
  a.remove()
}
</script>

<template>
  <div class="min-h-screen p4 bg-white">
    <BasicTitle title="我的推荐码" />

    <img
      v-if="imageUrl" :src="imageUrl"
      class="mt-10 mx-auto w-50 h-50"
    >

    <div
      v-if="imageUrl && isWeixin()"
      class="text-center mt-20 text-#666"
    >
      长按二维码保存到手机
    </div>
    <div
      v-if="imageUrl && !isWeixin()"
      class="text-center mt-20"
    >
      <van-button type="primary" class="!rounded" @click="saveImage">
        保存至手机
      </van-button>
    </div>
  </div>
</template>
