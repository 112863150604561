<script lang="ts" setup>
import { onMounted, ref, set } from 'vue'

import { useRouter } from 'vue-router/composables'
import Compressor from 'compressorjs'

// import { van-datetime-picker } from 'vant'
import axios from 'axios'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'
import { $formatDate, $formatErrorMessage, $getCurrentDate } from '@/helpers'
import { getExtension } from '@/utils'

const props = defineProps<{
  id: number | string
}>()

const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()

type Tab = 'info' | 'data'

const tab = ref<Tab>('info')
function onChangeTab(value: Tab) {
  tab.value = value
}

const arrival_time_picker = ref(false)
const registration_time_picker = ref(false)
const entry_time_picker = ref(false)
const exit_time_picker = ref(false)
const pickup_time_picker = ref(false)
const appointment_time_picker = ref(false)
const end_time_picker = ref(false)
const all_registration_fee_show = ref(false)
const self_pay_registration_fee_show = ref(false)
const proof_images = ref<any>([])
const file_images = ref<any>([])

const ticket = ref<any>()
onMounted(async () => {
  try {
    $showLoading()
    const { data } = await api.get(`/escort/serv_order/detail/${props.id}`)
    ticket.value = {
      ...data,
      escort_return_data: {
        ...data.escort_return_data,
        all_registration_fee: String(data.escort_return_data.all_registration_fee || ''),
        self_pay_registration_fee: String(data.escort_return_data.self_pay_registration_fee || ''),
      },
    }
    proof_images.value = data.escort_return_data.proof_images.map((item: string) => ({ url: item }))
    file_images.value = data.escort_return_data.file_images.map((item: string) => ({ url: item }))
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
})

const router = useRouter()
function gotoBack() {
  router.back()
}

const minDate = new Date()
function onClockIn(name: string) {
  if (!ticket.value.escort_return_data[name])
    set(ticket.value.escort_return_data, name, $getCurrentDate())
  onSave()
}

function uploadBeforeRead(file: File) {
  if (Array.isArray(file)) {
    const promises = file.map((item) => {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        // eslint-disable-next-line no-new
        new Compressor(item, {
          success: resolve,
          error(err: any) {
            console.error(err.message)
          },
        })
      })
    })

    return Promise.all(promises)
  }
  //   return Promise.all(file.map(item => uploadBeforeRead(item)))

  return new Promise((resolve) => {
    // compressorjs 默认开启 checkOrientation 选项
    // 会将图片修正为正确方向
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: resolve,
      error(err: any) {
        console.error(err.message)
      },
    })
  })
}

async function uploadAfterRead(file: { file: File; [key: string]: any }) {
  const files = Array.isArray(file) ? file : [file]
  for (const file of files) {
  // /escort/serv_order/get_upload_params
    file.status = 'uploading'
    file.message = '上传中...'
    // Get Signature from Server
    const { data: signature } = await api.get(`/escort/serv_order/get_upload_params?ext=${getExtension(file.file.name)}`)
    // axios.post()
    try {
      const { status } = await axios.post(signature.host,
        {
          key: signature.key,
          policy: signature.policy,
          OSSAccessKeyId: signature.accessKeyId,
          signature: signature.signature,
          file: file.file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      if (status !== 204) {
        file.status = 'failed'
        file.message = '上传失败'
        return
      }

      file.status = 'done'
      file.url = signature.url
    }
    catch (error: any) {
      console.error(error)
      file.status = 'failed'
      file.message = '上传失败'
    }
  }
}

async function onFinish() {
  try {
    $showLoading()
    await api.post('/escort/serv_order/finish', {
      serv_order_id: props.id,
      ...ticket.value.escort_return_data,
      ...formatDate(ticket.value.escort_return_data),
      proof_images: proof_images.value.map((item: any) => item.url),
      file_images: file_images.value.map((item: any) => item.url),
    })
    $toast('服务单完成成功')
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}

async function onSave() {
  try {
    $showLoading()
    await api.post('/escort/serv_order/update', {
      serv_order_id: props.id,
      ...ticket.value.escort_return_data,
      ...formatDate(ticket.value.escort_return_data),
      proof_images: proof_images.value.map((item: any) => item.url),
      file_images: file_images.value.map((item: any) => item.url),
    })
    $toast('暂存成功')
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}

function formatDate(data: any) {
  return {
    arrival_time: data.arrival_time ? $formatDate(data.arrival_time) : '',
    registration_time: data.registration_time ? $formatDate(data.registration_time) : '',
    entry_time: data.entry_time ? $formatDate(data.entry_time) : '',
    exit_time: data.exit_time ? $formatDate(data.exit_time) : '',
    pickup_time: data.pickup_time ? $formatDate(data.pickup_time) : '',
    appointment_time: data.appointment_time ? $formatDate(data.appointment_time) : '',
    end_time: data.end_time ? $formatDate(data.end_time) : '',
  }
}
</script>

<template>
  <div v-if="!ticket" />
  <div v-else class="min-h-screen bg-white">
    <BasicTitle title="服务单" />

    <div class="w-full h-10 flex items-center fixed left-0 bg-white">
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': tab === 'info' }"
        @click="onChangeTab('info')"
      >
        预约信息
      </p>
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': tab === 'data' }"
        @click="onChangeTab('data')"
      >
        数据上传
      </p>
    </div>

    <!-- Info -->
    <div v-if="tab === 'info'" class="mt-14 px-8">
      <div class="py-2 border rounded shadow-lg">
        <div class="px-4">
          <p class="font-bold">
            {{ ticket.hospital }}
          </p>
          <p class="font-bold">
            {{ ticket.date }}
          </p>
          <p>
            全程陪诊
          </p>
        </div>
        <div class="mt-2 pt-2 px-4 border-t">
          <div class="flex justify-between">
            <p>医生:</p>
            <p>{{ ticket.doctor }}</p>
          </div>
          <div class="flex justify-between">
            <p>科室:</p>
            <p>{{ ticket.department }}</p>
          </div>
          <div class="flex justify-between">
            <p>客服备注:</p>
            <p>{{ ticket.remark }}</p>
          </div>
          <div class="flex justify-between">
            <p>病情主诉:</p>
            <p>{{ ticket.disease_summary }}</p>
          </div>
          <div class="flex justify-between">
            <p>用药信息:</p>
            <p>{{ ticket.medication }}</p>
          </div>
        </div>
      </div>

      <p class="mt-8 mb-4 text-lg font-bold">
        就诊人
      </p>

      <div class="py-2 border rounded shadow-lg">
        <div class="px-4 flex gap-2 font-bold">
          <span>{{ ticket.patient.name }}</span>
          <span>{{ ['', '男', '女'][ticket.patient.gender] }}</span>
          <template v-if="ticket.patient.age">
            <span>|</span>
            <span>{{ ticket.patient.age }} 岁</span>
          </template>
        </div>
        <div class="mt-2 pt-2 px-4 border-t">
          <a class="block flex justify-between" :href="`tel:${ticket.patient.phone}`">
            <p>联系电话</p>
            <div class="flex items-center gap-2">
              <p>{{ ticket.patient.phone }}</p>
              <i-icon-park-outline-phone-call class="text-blue" />
            </div>
          </a>
        </div>
      </div>

      <!--  -->
      <div class="mt-10 flex gap-4 justify-between">
        <a
          class="block w-full h-10 lh-10 text-white text-center bg-green rounded-full"
          href="tel:010-60646093"
        >
          联系客服
        </a>
        <button
          class="w-full h-10 text-white bg-blue rounded-full"
          @click="gotoBack()"
        >
          返回首页
        </button>
      </div>
    </div>

    <!-- Data -->
    <div v-else class="mt-14">
      <div>
        <p class="mx-4 text-#999">
          时间打卡
        </p>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            到院时间:
          </p>
          <span v-if="ticket.escort_return_data.arrival_time" class="flex-1 text-center" @click="arrival_time_picker = true">{{ $formatDate(ticket.escort_return_data.arrival_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="arrival_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('arrival_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="arrival_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.arrival_time"
            :min-date="minDate"
            class="z-11" title="到院时间"
            @confirm="arrival_time_picker = false" @cancel="arrival_time_picker = false"
          />
        </div>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            取号时间:
          </p>
          <span v-if="ticket.escort_return_data.registration_time" class="flex-1 text-center" @click="registration_time_picker = true">{{ $formatDate(ticket.escort_return_data.registration_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="registration_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('registration_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="registration_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.registration_time"
            :min-date="minDate"
            class="z-11" title="取号时间"
            @confirm="registration_time_picker = false" @cancel="registration_time_picker = false"
          />
        </div>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            进诊室时间:
          </p>
          <span v-if="ticket.escort_return_data.entry_time" class="flex-1 text-center" @click="entry_time_picker = true">{{ $formatDate(ticket.escort_return_data.entry_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="entry_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('entry_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="entry_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.entry_time"
            :min-date="minDate"
            class="z-11" title="进诊室时间"
            @confirm="entry_time_picker = false" @cancel="entry_time_picker = false"
          />
        </div>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            出诊室时间:
          </p>
          <span v-if="ticket.escort_return_data.exit_time" class="flex-1 text-center" @click="exit_time_picker = true">{{ $formatDate(ticket.escort_return_data.exit_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="exit_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('exit_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="exit_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.exit_time"
            :min-date="minDate"
            class="z-11" title="出诊室时间"
            @confirm="exit_time_picker = false" @cancel="exit_time_picker = false"
          />
        </div>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            取药时间:
          </p>
          <span v-if="ticket.escort_return_data.pickup_time" class="flex-1 text-center" @click="pickup_time_picker = true">{{ $formatDate(ticket.escort_return_data.pickup_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="pickup_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('pickup_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="pickup_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.pickup_time"
            :min-date="minDate"
            class="z-11" title="取药时间"
            @confirm="pickup_time_picker = false" @cancel="pickup_time_picker = false"
          />
        </div>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            检查预约时间:
          </p>
          <span v-if="ticket.escort_return_data.appointment_time" class="flex-1 text-center" @click="appointment_time_picker = true">{{ $formatDate(ticket.escort_return_data.appointment_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="appointment_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('appointment_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="appointment_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.appointment_time"
            :min-date="minDate"
            class="z-11" title="检查预约时间"
            @confirm="appointment_time_picker = false" @cancel="appointment_time_picker = false"
          />
        </div>
        <div class="my-2 mx-4 flex items-center justify-between">
          <p class="w-26">
            结束时间:
          </p>
          <span v-if="ticket.escort_return_data.end_time" class="flex-1 text-center" @click="end_time_picker = true">{{ $formatDate(ticket.escort_return_data.end_time) }}</span>
          <span v-else class="flex-1 text-center text-#999" @click="end_time_picker = true">请选择时间</span>
          <button
            class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
            @click="onClockIn('end_time')"
          >
            打卡
          </button>
        </div>
        <div v-if="end_time_picker" class="w-full fixed bottom-0 left-0">
          <div class="fixed inset-0 z-10 bg-#00000020" />
          <van-datetime-picker
            v-model="ticket.escort_return_data.end_time"
            :min-date="minDate"
            class="z-11" title="结束时间"
            @confirm="end_time_picker = false" @cancel="end_time_picker = false"
          />
        </div>
      </div>

      <div class="mt-2 pt-2 border-t">
        <p class="mx-4 text-#999">
          挂号费
        </p>
        <div class="mx-4 py-2 flex">
          <p>全额挂号费:</p>
          <span v-if="ticket.escort_return_data.all_registration_fee" class="flex-1 text-right" @click="all_registration_fee_show = true">{{ ticket.escort_return_data.all_registration_fee }}</span>
          <span v-else class="flex-1 text-right text-#999" @click="all_registration_fee_show = true">请输入金额</span>
          <van-number-keyboard
            v-model="ticket.escort_return_data.all_registration_fee"
            extra-key="."
            close-button-text="完成"
            :show="all_registration_fee_show"
            :maxlength="6"
            @blur="all_registration_fee_show = false"
          />
        </div>
        <div class="mx-4 py-2 flex">
          <p>医保后挂号费:</p>
          <span v-if="ticket.escort_return_data.self_pay_registration_fee" class="flex-1 text-right" @click="self_pay_registration_fee_show = true">{{ ticket.escort_return_data.self_pay_registration_fee }}</span>
          <span v-else class="flex-1 text-right text-#999" @click="self_pay_registration_fee_show = true">请输入金额</span>
          <van-number-keyboard
            v-model="ticket.escort_return_data.self_pay_registration_fee"
            extra-key="."
            close-button-text="完成"
            :show="self_pay_registration_fee_show"
            :maxlength="6"
            @blur="self_pay_registration_fee_show = false"
          />
        </div>
      </div>

      <div class="mt-2 pt-2 border-t">
        <p class="mx-4 text-#999">
          诊断
        </p>
        <div class="mx-4 py-2 flex flex-col">
          <p>医生诊断:</p>
          <textarea v-model="ticket.escort_return_data.diagnosis" class="flex-1" placeholder="医生诊断" />
        </div>
        <div class="mx-4 py-2 flex flex-col">
          <p>用药信息:</p>
          <textarea v-model="ticket.escort_return_data.drug_information" class="flex-1" placeholder="用药信息" />
        </div>
        <div class="mx-4 py-2 flex flex-col">
          <p>医生治疗方案:</p>
          <textarea v-model="ticket.escort_return_data.treatment_plan" class="flex-1" placeholder="医生治疗方案" />
        </div>
        <div class="mx-4 py-2 flex flex-col">
          <p>备注信息:</p>
          <textarea v-model="ticket.escort_return_data.remark" class="flex-1" placeholder="备注信息" />
        </div>
      </div>

      <div class="mt-2 pt-2 border-t">
        <p class="mx-4 text-#999">
          文件上传
        </p>
        <div class="mx-4 py-2 flex flex-col">
          <p>联系客服证明（请上传电话或短信联系截图）:</p>
          <p class="my-2">
            图片上传
          </p>
          <van-uploader v-model="proof_images" multiple :before-read="uploadBeforeRead" :after-read="uploadAfterRead" />
          <!-- ticket.escort_return_data.proof_images -->
          <!-- <van-uploader
            :file-list="(ticket.escort_return_data.proof_images).map((item: any) => ({ url: item }))"
          /> -->
          <!-- <div class="w-20 h-20 border flex items-center justify-center">
            <i-material-symbols-android-camera-outline class="text-#999" />
          </div> -->
        </div>
        <div class="mx-4 py-2 flex flex-col">
          <p>相关信息材料上传:</p>
          <!-- ticket.escort_return_data.file_images -->
          <p class="my-2">
            图片上传
          </p>
          <van-uploader v-model="file_images" multiple :before-read="uploadBeforeRead" :after-read="uploadAfterRead" />
          <!-- <div class="w-20 h-20 border flex items-center justify-center">
            <i-material-symbols-android-camera-outline class="text-#999" />
          </div> -->
        </div>
      </div>

      <div class="py-10 mx-4 flex gap-4 justify-between">
        <button
          class="w-full h-10 text-white bg-green rounded-full"
          @click="onFinish"
        >
          完成服务单
        </button>
        <button
          class="w-full h-10 text-white bg-blue rounded-full"
          @click="onSave()"
        >
          暂存
        </button>
      </div>
    </div>
  </div>
</template>
