<script lang="ts" setup>
import { useInfiniteScroll } from '@vueuse/core'
import { ref } from 'vue'
import { api } from '@/api'
import { $formatDate } from '@/helpers'

const show_min_created_at = ref(false)
const min_created_at = ref('')
const show_max_created_at = ref(false)
const max_created_at = ref('')

const infiniteScrollRef = ref<HTMLDivElement>()

const list = ref<any[]>([])
const page = ref(1)
const ending = ref(false)
const fetching = ref(false)
const error = ref(null)
async function fetchRecommends() {
  fetching.value = true
  try {
    const { data } = await api.get(`/escort/shopOrder/getHaveEscortOrderList?start_time=${min_created_at.value ? $formatDate(min_created_at.value) : ''}&end_time=${max_created_at.value ? $formatDate(max_created_at.value) : ''}&page=${page.value}&page_size=20`)
    list.value = list.value.concat(data.data)
    if (page.value * 20 >= data.total)
      ending.value = true

    page.value = page.value + 1
  }
  catch (err: any) {
    error.value = err
  }
  finally {
    fetching.value = false
  }
}

function onSearch() {
  list.value = []
  page.value = 1
  ending.value = false
}
useInfiniteScroll(infiniteScrollRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchRecommends()
  },
  { distance: 50 },
)
</script>

<template>
  <div ref="infiniteScrollRef" class="h-screen pb-10 overflow-y-scroll bg-white">
    <BasicTitle title="分销订单" />

    <div class="flex items-center gap-2">
      <div class="flex-1 p-2 flex items-center text-sm">
        <p v-if="min_created_at" @click="show_min_created_at = true">
          {{ $formatDate(min_created_at) }}
        </p>
        <p v-else class="text-#999" @click="show_min_created_at = true">
          请选择开始时间
        </p>
        <p class="flex-1 text-center">
          至
        </p>
        <p v-if="max_created_at" @click="show_max_created_at = true">
          {{ $formatDate(max_created_at) }}
        </p>
        <p v-else class="text-#999" @click="show_max_created_at = true">
          请选择结束时间
        </p>
      </div>
      <div class="ml-2">
        <van-button type="primary" size="small" @click="onSearch">
          搜索
        </van-button>
      </div>
    </div>

    <div class="mt-4 px-4 space-y-4">
      <div v-for="item in list" :key="item.id" class="border rounded">
        <div class="flex items-center justify-between px-4 py-3 text-#999">
          <div>{{ item.pay_at }}</div>
          <div>{{ item.status_name }}</div>
        </div>
        <div class="border-t px-4 py-3">
          <div class="flex items-center justify-between gap-4 font-medium">
            <div class="truncate flex-1 w-0">
              {{ item.product_name || '--' }}
            </div>
            <div>
              ￥{{ item.total_price }}
            </div>
          </div>
          <div class="text-#999 mt-2">
            订单号: {{ item.order_no }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="ending" class="py-15px text-13px text-#999 text-center">
      我们是有底线的~
    </div>
    <div v-else-if="fetching" class="py-15px flex items-center justify-center gap-4 text-13px text-#999">
      <div class="animate-spin">
        <i-icon-park-outline-loading />
      </div>
      <p>加载中...</p>
    </div>

    <div v-if="show_min_created_at" class="w-full fixed bottom-0 left-0">
      <div class="fixed inset-0 z-10 bg-#00000020" />
      <van-datetime-picker
        v-model="min_created_at" class="z-11" title="请选择开始时间" @confirm="show_min_created_at = false"
        @cancel="show_min_created_at = false"
      />
    </div>
    <div v-if="show_max_created_at" class="w-full fixed bottom-0 left-0">
      <div class="fixed inset-0 z-10 bg-#00000020" />
      <van-datetime-picker
        v-model="max_created_at" class="z-11" title="请选择结束时间" @confirm="show_max_created_at = false"
        @cancel="show_max_created_at = false"
      />
    </div>
  </div>
</template>
