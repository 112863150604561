import { type RouteConfig } from 'vue-router'
import Login from '@/pages/login.vue'
import Home from '@/pages/home.vue'
import Ticket from '@/pages/ticket.vue'
import Authorize from '@/pages/authorize.vue'
import Me from '@/pages/me.vue'
import Qrcode from '@/pages/qrcode.vue'
import Distribution from '@/pages/distribution.vue'
import Recommended from '@/pages/recommended.vue'
import ChangeMobile from '@/pages/change-mobile.vue'
import Error from '@/pages/error.vue'
import NotFound from '@/pages/404.vue'
import MyOrder from '@/pages/my-order.vue'
import DistributionOrder from '@/pages/distribution-order.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: '登录', requiresAuth: false },
  },
  {
    path: '/authorize',
    name: 'authorize',
    component: Authorize,
    meta: { title: '授权中', requiresAuth: false },
    props: route => ({
      code: route.query.code,
      state: route.query.state,
      redirect: route.query.redirect,
    }),
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: '首页' },
    props: true,
  },
  { path: '/me', name: 'me', component: Me, meta: { title: '我的' } },
  {
    path: '/ticket/:id',
    name: 'ticket',
    component: Ticket,
    meta: { title: '服务单' },
    props: true,
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: Qrcode,
    meta: { title: '我的推荐码' },
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: Distribution,
    meta: { title: '二级分销' },
  },
  {
    path: '/recommended',
    name: 'recommended',
    component: Recommended,
    meta: { title: '推荐用户' },
  },
  {
    path: '/my-order',
    name: 'my-order',
    component: MyOrder,
    meta: { title: '直推订单' },
  },
  {
    path: '/distribution-order',
    name: 'distribution-order',
    component: DistributionOrder,
    meta: { title: '分销订单' },
  },
  {
    path: '/change-mobile',
    name: 'change-mobile',
    component: ChangeMobile,
    meta: { title: '修改手机' },
  },
  {
    path: '/500',
    name: 'error',
    component: Error,
    meta: { title: '服务器错误', requiresAuth: false },
  },
  {
    path: '*',
    name: 'notFound',
    alias: '/404',
    component: NotFound,
    meta: { title: '404', requiresAuth: false },
  },
]

export default routes
